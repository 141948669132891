import { MInput, MLabel, MSelect, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";
import React from "react";

export class FormDOC01 extends ValidatedForm {

    PAYLOADS = {
        schoolTypes: [
            { value: "", label: "Selezionare" },
            { value: "Infanzia", label: "Infanzia" },
            { value: "Primaria", label: "Primaria" },
            { value: "Secondaria di primo grado", label: "Secondaria di primo grado" },
            { value: "Secondaria di secondo grado", label: "Secondaria di secondo grado" }
        ],
    }

    FIELDS_GROUP = [
        [{
            field: "mlabel",
            label: "Indicare le tre annualità di servizio sul sostegno, anche non continuative, negli ultimi cinque anni",
            component: MLabel,
            className: "col-md-12 pt-3"
        }],
        [{
            field: "mlabel",
            label: "1)	Annualità di servizio sul sostegno n. 1",
            component: MLabel,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "istitution01",
            field: "istitution01",
            label: "Grado/ordine di svolgimento del servizio",
            payload: { options: this.PAYLOADS.schoolTypes },
            component: MSelect,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "aa01",
            field: "aa01",
            label: "Anno scolastico di svolgimento del servizio",
            component: MInput,
            infoText: "inserire formato AAAA/AAAA",
            className: "col-md-6 pt-3",
        },
        {
            id: "durata01",
            field: "durata01",
            label: "Giorni servizio",
            infoText: "Giorni di servizio complessivi nel singolo anno scolastico",
            component: MInput,
            type: "number",
            className: "col-md-6",
        }
        ],
        [{
            field: "mlabel",
            label: "2)	Annualità di servizio sul sostegno n. 2",
            component: MLabel,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "istitution02",
            field: "istitution02",
            label: "Grado/ordine di svolgimento del servizio",
            payload: { options: this.PAYLOADS.schoolTypes },
            component: MSelect,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "aa02",
            field: "aa02",
            label: "Anno scolastico di svolgimento del servizio",
            component: MInput,
            infoText: "inserire formato AAAA/AAAA",
            className: "col-md-6 pt-3",
        },
        {
            id: "durata02",
            field: "durata02",
            label: "Giorni servizio",
            infoText: "Giorni di servizio complessivi nel singolo anno scolastico",
            component: MInput,
            type: "number",
            className: "col-md-6",
        }
        ], 
        [{
            field: "mlabel",
            label: "3)	Annualità di servizio sul sostegno n. 3",
            component: MLabel,
            className: "col-md-12 pt-3"
        }],[{
            id: "istitution03",
            field: "istitution03",
            label: "Grado/ordine di svolgimento del servizio",
            payload: { options: this.PAYLOADS.schoolTypes },
            component: MSelect,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "aa03",
            field: "aa03",
            label: "Anno scolastico di svolgimento del servizio",
            component: MInput,
            infoText: "inserire formato AAAA/AAAA",
            className: "col-md-6 pt-3",
        },
        {
            id: "durata03",
            field: "durata03",
            label: "Giorni servizio",
            infoText: "Giorni di servizio complessivi nel singolo anno scolastico",
            component: MInput,
            type: "number",
            className: "col-md-6",
        }
        ]
    ];

    ERROR_MESSAGES = {
        istitution01: "Inserire il grado/ordine di svolgimento del servizio",
        istitution02: "Inserire il grado/ordine di svolgimento del servizio",
        istitution03: "Inserire il grado/ordine di svolgimento del servizio",
        aa01: "Inserire l'anno accademico in formato AAAA/AAAA",
        aa02: "Inserire l'anno accademico in formato AAAA/AAAA",
        aa03: "Inserire l'anno accademico in formato AAAA/AAAA",
        durata01:"Inserire i giorni di servizio",
        durata02:"Inserire i giorni di servizio",
        durata03:"Inserire i giorni di servizio",
    };

    validation = {
        istitution01: (value) => value !== "",
        aa01: (value) => this.validateAA(value),
        durata01: value => (value>0 && /^\d*$/.test(value)),  
        istitution02: (value) => value !== "",
        aa02: (value) => this.validateAA(value),
        durata02: value => (value>0 && /^\d*$/.test(value)),  
        istitution03: (value) => value !== "",
        aa03:(value) => this.validateAA(value),
        durata03: value =>(value>0 && /^\d*$/.test(value)),     
    };

    validateAA = (value) => {
        let aa = /^(\d{4})\/(\d{4})$/.test(value);
    
        if (!aa) {
          return false;
        } else {
          aa = value.split("/");
          if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
            return true;
          } else {
            return false;
          }
        }
      }; //validateAA
      
    emptyFields = {
        istitution01: "",
        aa01: "",
        durata01: "",
        istitution02: "",
        aa02: "",
        durata02: "",
        istitution03: "",
        aa03: "",
        durata03: "",        
    };

    state = {        
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields,
        },
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {

        const formPayload = payloadBuilder(this.state);

        const payload =[];
        payload.push({
            qualificationType : "AbilitazioneSostegno",
            qualificationCode : "DOC01",       
            template : 'AbilitazioneSostegno',
            aa: formPayload.aa01,
            durata: formPayload.durata01,
            istitution: formPayload.istitution01           
        
        })
        
        payload.push({
            qualificationType : "AbilitazioneSostegno",
            qualificationCode : "DOC01",       
            template : 'AbilitazioneSostegno',
            aa: formPayload.aa02,
            durata: formPayload.durata02,
            istitution: formPayload.istitution02
        
        })

        payload.push({
            qualificationType : "AbilitazioneSostegno",
            qualificationCode : "DOC01",       
            template : 'AbilitazioneSostegno',
            aa: formPayload.aa03,
            durata: formPayload.durata03,
            istitution: formPayload.istitution03
        
        })

        console.log(payload);
        return payload;
    };

    render() {
        const { defaultValues } = this.state;
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (
            <>              
                    {code}              
            </>
        );
    }
}