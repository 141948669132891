import React, { Component } from "react";
import { ROUTES } from "const";
import { Row, Col } from "design-react-kit";
import ReactHtmlParser from "react-html-parser";
import "./HomeCourses.css";

const COURSE_BOXES = [
  /*{
    link: `/corso-online/CSU24CFU`,
    image: `/img/courses/new/24cfu.jpg`,
    title: "Corsi Singoli 24CFU",
    subTitle: "24CFU Corsi per l'insegnamento",
    highlights: "Iscrizioni chiuse",
  },
  {
    link: `/corso-online/MUMC24`,
    image: `/img/courses/new/master-24cfu2.jpeg`,
    title: "Master 24CFU",
    subTitle: "Comprensivo dei 24CFU per l'insegnamento",
    highlights: "Iscrizioni chiuse",
  },*/
  {
    link: ROUTES.FORM_60CFU,
    image: `/img/courses/new/30_60cfu.jpg`,
    title: "Corsi Abilitanti 30 CFU - A.A. 23/24",
    subTitle: 'Solo per docenti già abilitati su materia o specializzati sul sostegno',
    highlights: "ISCRIZIONE APERTE <br/> A partire da € 1500",
  },
  {
    link: '/corso-online-mnemosine/ADESIONI_SOSTEGNO',
    image: `/img/courses/new/adesioni_sostegno.jpg`,
    title: "Corso per Docenti",
    subTitle: "<ul><li>con sostegno conseguito all'estero</li><li>con 3 anni di servizio sul sostegno</li></ul><br/> <br/> ",    
  }, 
  {
    link: '/page/corsi-abilitanti-30-CFU',
    image: `/img/courses/new/30cfu_34_24.jpg`,
    title: "Corsi Abilitanti 30 CFU - A.A. 24/25",
    subTitle: "Solo per docenti già abilitati su materia o specializzati sul sostegno<br/><br/>",   
    highlights: "A BREVE"    
  },
  /*  {
     link: ROUTES.ENROLL_TFA_IX_CICLO_RISERVATO,
     image: `/img/courses/banner_u24cfu.webp`,
     title: "TFA Sostegno 2024",
     subTitle: 'Solo riservisti',
     highlights: "(con 3 anni di servizio su sostegno)",
   }, */
  {
    link: `${ROUTES.PAGE_CORSISINGOLICSURCSU}/`,
    image: `/img/courses/new/ucsu.jpeg`,
    title: "Corsi Singoli  Universitari",
    subTitle: "",
    highlights: "",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/MU`,
    image: `/img/courses/new/mu.jpg`,
    title: "Master di I livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da €440",
  },
  {
    link: ROUTES.COURSE_SSD_BROWSE,
    image: `/img/courses/new/mr.jpg`,
    title: "Corsi Singoli e Master",
    subTitle:
      "A11, A18, A28, A21, A26, A46... <br/><b>Nuovi Master: A26, A27, A28</b>",
    highlights: "Da €440",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/BU-I`,
    image: `/img/courses/new/bu-i.jpg`,
    title: "Diplomi di perfezionamento biennale",
    // subTitle: "1500 ore e 60 CFU per ogni annualità",
    highlights:
      "Docenti di ruolo: punti 5 nei trasferimenti <br/>Precari: punti 2 (oltre ai 3 corsi annuali)",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/UCLIL`,
    image: `/img/courses/new/clil.jpg`,
    title: "Perfezionamento CLIL",
    subTitle: "1500 ore e 60 CFU",
    highlights: "A soli € 498",
  },
  {
    link: `${ROUTES.PAGE_PERFEZIONAMENTOPDUPLU}/`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  }, 
  {
    link: `${ROUTES.COURSE_BROWSE}/MDU`,
    image: `/img/courses/new/mdu.jpg`,
    title: "Master di II livello",
    subTitle: "1500 ore e 60 CFU",
    highlights: "",
  },
  /*{
    link: `${ROUTES.COURSE_BROWSE}/PLU`,
    image: `/img/courses/new/plu.jpg`,
    title: "Diplomi di perfezionamento post laurea",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/PDU`,
    image: `/img/courses/new/pd.jpg`,
    title: "Diplomi di perfezionamento post diploma",
    subTitle: "1500 ore e 60 CFU",
    highlights: "Da € 389",
  },*/

  /* {
     link: `${ROUTES.PAGE_CORSIDILAUREA}`,
     image: `/img/courses/new/corsi-di-laurea.jpg`,
     title: "Corsi di Laurea",
     subTitle:
       "<br/>Lauree VERE per un futuro lavorativo REALE: da Scienze Infermieristiche a Medicina!",
   },*/
  /*{
    image: `/img/courses/new/nuovi_master.jpg`,
    title: "A breve Master per:",
    subTitle: "&checkmark; A-26 Matematica<br/> &checkmark; A-27 Matematica e Fisica<br/> &checkmark; A-28 Matematica e Scienze<br/><b>Conseguibili da Febbraio 2024 (entro l'aggiornamento Grad. Istituto)</b>",
    highlights: "",
  },*/
  {
    link: `${ROUTES.COURSE_BROWSE}/INFO`,
    image: `/img/courses/new/info.jpg`,
    title: "",
    subTitle:
      '<h4 class="programme-card__title"> ✓ Certificazione Internazionale di Alfabetizzazione Digitale</h4><h4 class="programme-card__title"> ✓ Certificazioni Informatiche</h4>',
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/ENG`,
    image: `/img/courses/new/eng.jpg`,
    title: "Inglese B2-C1",
    subTitle: "English Speaking Board (ESB)",
    highlights: "Da € 148",
  },

  // {
  //   link: "https://www.asuniver.it/corso-di-sostegno-allestero/",
  //   image: `/img/courses/new/sostegno_spagna.jpg`,
  //   title: "Corso di Sostegno all’estero",
  // },
];

const COURSE_BOXES_SMALL = [
  /*{
    link: `${ROUTES.COURSE_MUPA_BROWSE}`,
    image: `/img/courses/new/mupabox.jpg`,
    title: "Master per Pubblica Amministrazione e Forze Armate",
    subTitle: "",
    highlights: "",
  },*/
  {
    link: '/corso-online-mnemosine/TFA_IX_CICLO_ORDINARIO',
    image: `/img/courses/banner_tfa_02.jpg`,
    title: "TFA Sostegno Ordinario 2024",
  },
  /*{
    link: ROUTES.ENROLL_TFA_IX_CICLO + '/TFA_IX_CICLO_RISERVATO',
    image: `/img/courses/banner_tfa_01.jpg`,
    title: "TFA Sostegno 2024",
    subTitle: 'Solo riservisti',
    highlights: "(con 3 anni di servizio su sostegno)",
  },
  {
    link: "/corso-online-mnemosine/TFA_IX_CICLO_SOPRANNUMERARI_IDONEI",
    image: `/img/courses/banner_tfa_01.jpg`,
    title: "TFA Sostegno 2024",
    subTitle: 'Solo per soprannumerari',
  },*/
  {
    link: `${ROUTES.COURSE_BROWSE}/ATA`,
    image: `/img/courses/new/ata.jpg`,
    title: "Dattilografia e ICDL IT-Security",
  },
  {
    link: `${ROUTES.PAGE_MASTERMUPAMSLU}`,
    image: `/img/courses/new/mupabox.jpg`,
    title: "Master per ",
    subTitle: "",
    highlights: " - Pubblica Amministrazione <br/>- Forze Armate",
  },
  {
    link: `/corso-online/ABA`,
    image: `/img/courses/new/aba-v2.jpg`,
    title: "ABA",
    subTitle: "Applied Behavior Analysis",
  },
  /*{
    link: `/corso-online/TDIDEM22`,
    image: `/img/courses/new/didattica-delle-emozioni.jpg`,
    title: "Didattica delle emozioni",
    subTitle: `Il metodo dell'educazione emotiva a scuola`,
  },*/
  {
    link: `/corsi-mnemosine/FC`,
    image: `/img/courses/new/formazione-continua-v2.jpg`,
    title: "Formazione continua",
    subTitle: "Valorizza il buono scuola con i nostri corsi",
  },
  {
    link: `${ROUTES.COURSE_BROWSE}/RCPFU`,
    image: `/img/courses/new/5cfu.png`,
    title: "Percorso di Formazione 5 CFU",
    subTitle: "Per docenti vincitori di Concorso - Esami in PRESENZA",
    highlights: "Iscrizioni chiuse",
  },
];
export class HomeCourses extends Component {
  componentDidMount() { }

  render() {
    return (
      <>
        {/* <HomeCoursesGrid courses={FEATURED_HOME_COURSES} />
        <SlideMoreCourses courses={MORE_HOME_COURSES} /> */}

        {/*<section className="programmes-single border">
          <a href="/corso-online-formazione/PFU5CFU2" className="programmes-single__content">
            <div className="programmes-single__wrapper ">
              <Row>
                <Col md={9}>  <h2 className="programmes-single__title">
                  Percorso di Formazione di 5 CFU DM 108/2022 - <span className="text-red text-hightlights span-price"> solo € 150</span>
                </h2></Col>
                <Col md={3}>
                  <h2 className="programmes-single__title">Iscrizioni Aperte</h2></Col>
              </Row>
              <h6 className="text-highlights">
                Riservato ai Docenti vincitori del "Concorso straordinario Bis"{" "}
                <span className="small">
                  - esame in <strong>PRESENZA</strong> in <strong>100 città</strong> (ripetibile gratuitamente)!
                </span>
              </h6>
            </div>
          </a>
      </section>*/}

        {/*<section className="programmes">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row>
                <a href="https://www.asuniver.it/corso-di-sostegno-allestero/" rel="noopener noreferrer"  target="_blank">
                  <img
                    className="w-100"
                    src="/img/home/sostegno_banner.jpg"
                    alt="Corso di Sostegno all'estero"
                  />
                </a>
              </Row>
            </div>
          </div>
    </section>*/}
        <section className="secondary-banner-home">
          <div className="programmes-single__wrapper ">
            <Row>
              <Col md={12}>
                <a className="wrapper-link" href="../page/al-fine-di-tutelare-i-corsisti-Mnemosine" rel="noopener noreferrer"  target="_blank">
                  <h2 className="text-white">
                    Al fine di tutelare i corsisti Mnemosine (e non solo) è interessante sapere che....
                  </h2>
                </a>
              </Col>
            </Row>
          </div>
        </section>
        {/*<section className="programmes">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row>
                <a href="../corsi-mnemosine/UCSU" rel="noopener noreferrer" target="_blank">
                  <img
                    className="w-100"
                    src="/img/home/banner_ucsu_home.png"
                    alt="Con noi esami in presenza"
                  />
                </a>

              </Row>
            </div>
          </div>
  </section>*/}
        <section className="programmes">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row>
                {COURSE_BOXES.map((course, i) => (
                  <Col xs={12} sm={6} md={4} lg={4} key={`course-${i}`}>
                    <div className="swiper-slide">
                      <div className="programme-card">
                        <a className="programme-card__link" href={course.link}>
                          <div className="programme-card__header">
                            <img
                              className="programme-card__img"
                              alt={course.title}
                              src={course.image}
                            />
                          </div>
                          <div className="programme-card__body">
                            <h4 className="programme-card__title">
                              {course.title && course.title.length > 0 && (
                                <>
                                  {ReactHtmlParser(course.title)} <br />
                                </>
                              )}
                              {course.subTitle && (
                                <>
                                  {/* <span className="small">
                                    {course.subTitle}
                                  </span> */}
                                  <div
                                    className="small mypt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: course.subTitle,
                                    }}
                                  />
                                </>
                              )}
                            </h4>
                            {course.highlights && (
                              <h6
                                className="text-red  text-highlights"
                                dangerouslySetInnerHTML={{
                                  __html: course.highlights,
                                }}
                              />
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
        <section className="secondary-icon-home programmes">
          <div className="programmes-single__wrapper ">
            <Row>
              <Col md={12}>
                <a className="wrapper-link" href="https://www.formazionedocenti.it/page/manifestazioni-di-interesse-per-insegnamenti-nei-percorsi-abilitanti" rel="noopener noreferrer"  target="_blank">
                  <h2 className="text-white">
                    COLLABORA CON
                    <img className="image-icon" alt="Logo Unidarc" src="/img/partners/Unidarc_logo.png" />
                    Presenta la tua candidatura adesso
                  </h2>
                </a>
              </Col>
            </Row>
          </div>
        </section>
        <section className="programmes">
          <a href="https://www.transizionedigitalescuola.it/" rel="noopener noreferrer"  target="_blank">
            <img
              className="image-secondary-banner p-3"
              src="/img/home/transizione_digitale_dm_66_2023.jpg"
              alt="Didattica digitale integrata e formazione alla transizione digitale per il personale scolastico (D.M. 66/2023)"
            />
          </a>
        </section>
        <section className="programmes">
          <h2 className="programmes-title">Altri Corsi</h2>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Row className="w-100">
                {COURSE_BOXES_SMALL.map((course, i) => (
                  <Col xs={12} sm={6} md={4} lg={4} key={`course-${i}`}>
                    <div className="swiper-slide">
                      <div className="programme-card">
                        <a className="programme-card__link" href={course.link}>
                          <div className="programme-card__header">
                            <img
                              className="programme-card__img"
                              alt={course.title}
                              src={course.image}
                            />
                          </div>
                          <div className="programme-card__body">
                            <h4 className="programme-card__title">
                              {course.title}
                              {course.subTitle && (
                                <div className="small mt-2">
                                  {course.subTitle}
                                </div>
                              )}
                            </h4>
                            {course.highlights && (
                              <h6
                                className="text-highlights"
                                dangerouslySetInnerHTML={{
                                  __html: course.highlights,
                                }}
                              />
                            )}
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </section>
      </>
    );
  }
}
