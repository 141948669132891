import React from "react";
import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";
import {
  BoxDanger,
  BoxSuccess,
  BoxWarning,
  SupportoTecnico,
} from "components/misc";
import { PAYMENT_TYPES, ROUTES } from "const";
import {
  userService,
  enrollmentService,
  networkErrorHelper,
  payloadBuilder,
  enrollmentAdminService,
  CourseHelper,
} from "libs";

import {
  MCheckbox,
  MInput,
  ValidatedForm,
} from "components/forms";

import {
  Button, Container,
  Row, Card, CardBody, Col,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "design-react-kit";

// tracciamento cookie statistici
import { getStatCookie } from "libs/helpers/cookieHelper";
import { getCurrentUser } from "store/actions";

export class FreeCourseShopper extends ValidatedForm {

  emptyFields = {
    name: "",
    surname: "",
    emailLogin: "",
    passwordLogin: "",
    email: "",
    password: "",
    accept1: false,
    accept2: false,
    //  canEnroll: true
  };

  defaultState = {
    collapseOpen1: false,
    collapseOpen2: false,
  };

  state = {
    showEnrollResult: false,
    toLogin: false,
    toRegister: false,
    courseCode: 0,
    enrollment: null,
    loading: true,
    loadingError: false,
    ...this.emptyFields,
    ...this.defaultState,
    alertMessages: [],
    defaultValues: {
      ...this.emptyFields,
    },
    courseEnrolled: null,
    userId: null,
  };


  FIELDS_LOGIN = [
    [
      {
        field: "emailLogin",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        field: "passwordLogin",
        label: "Password",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_REGISTER = [
    [
      {
        field: "name",
        label: "Nome",
        component: MInput,
        autoComplete: "off",
        className: "col-md-6",
      },
      {
        field: "surname",
        label: "Cognome",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        field: "emailRepeat",
        label: "Ripeti Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "password",
        label: "password a libera scelta",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
      {
        field: "passwordRepeat",
        label: "Ripeti Password",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
    ],
  ];

  ERROR_MESSAGES = {
    name: "Inserire un Nome  valido",
    surname: "Inserire un Cognome valido",
    emailLogin: `L'Email non è valida`,
    passwordLogin: "Specificare la tua password di accesso",
    email: `L'Email non è valida`,
    emailRepeat: "Le due email non coincidono",
    password:
      "La Password non è valida (lunghezza almeno 6 caratteri, almeno una lettera maiuscola e almeno un numero)",
    passwordRepeat: "Le due password non coincidono",
  };

  validation = {
    name: (value) => value.length > 2,
    surname: (value) => value.length > 3,
    emailLogin: (value) => /\S+@\S+\.\S+/.test(value),
    passwordLogin: (value) => value.length > 5,
    email: (value) => /\S+@\S+\.\S+/.test(value),
    emailRepeat: (value, { email }) => email && value === email.value,
    password: (value) => /(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value),
    passwordRepeat: (value, { password }) =>
      password && value === password.value,
  };

  componentDidMount() {
    this.loadRemote(this.props.courseCode);
  }

  loadRemote(courseCode) {

    const currentAA = CourseHelper.getCurrentAA(courseCode);

    enrollmentAdminService
      .examSession(courseCode)
      .then(({ data }) => {
        const { payload } = data;
        console.log(payload);
        this.setState({
          idExamSession: payload.idExamSession,
          courseCode,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({ loadingError: true, loading: false});
      });

    if (this.props.isLoggedIn) {
      enrollmentService
        .myEnrollment(courseCode, currentAA)
        .then(({ data }) => {
          if (data.payload.enrollment) {
            this.setState({ enrollment: data.payload.enrollment });
          }
          this.setState({ loading: false, courseCode });
        })
        .catch((error) => {
          if (!networkErrorHelper.is404(error)) {
            this.setState({ loadingError: true });
          }

          this.setState({ loading: false, courseCode });
        });
    }
    // verify userCourseAbilitation

    if (this.props.isLoggedIn) {
      const user = getCurrentUser();
      this.setState({ userId: user.id })
    }
  }

  toggle = (id) => {
    this.setState({
      ...this.defaultState,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`],
    });
  };

  onAccept = (idAccept, checked) => {
    this.setState({ [idAccept]: checked });
  };

  async checkLogin() {
    let message = "";
    // verify login fields
    const isEmail = this.checkValidationField("emailLogin");
    const isPassword = this.checkValidationField("passwordLogin");

    // verify login data
    if (isEmail && isPassword) {
      const { emailLogin, passwordLogin } = this.state;
      const p = userService
        .checkLogin(emailLogin.value, passwordLogin.value)
        .then(({ data }) => {
          this.setState({
            toLogin: true,
            toRegister: false,
            userId: data.payload.userId
          });
        })
        .catch((error) => {
          if (networkErrorHelper.is404(error)) {
            message =
              'I dati di login non sono validi. Se hai dimenticato la password <a target="_blank" href="/password-reset">clicca qui per reimpostarla</a>';
          } else {
            message =
              "Si è verificato un errore durante la verifica dell'account";
          }
        });

      await Promise.resolve(p);
    } else {
      message = "Inserire i dati evidenziati in rosso";
    }

    return message;
  }

  async checkRegistration() {
    let message = "";

    // verify registratoin fields
    // verify login fields
    const isEmail = this.checkValidationField("email");
    const isPassword = this.checkValidationField("password");
    const isEmailRepeat = this.checkValidationField("emailRepeat");
    const isPasswordRepeat = this.checkValidationField("passwordRepeat");
    const name = this.checkValidationField("name");
    const surname = this.checkValidationField("surname");

    // verify login data
    if (
      isEmail &&
      isPassword &&
      isEmailRepeat &&
      isPasswordRepeat &&
      name &&
      surname
    ) {
      const { email } = this.state;

      const p = userService
        .checkEmail(email.value)
        .then(({ data }) => {
          message =
            "Esiste già un account con questa email. Prova a selezionare 'ho un account' ";
        })
        .catch((error) => {
          if (networkErrorHelper.is404(error)) {
            this.setState({ toRegister: true, toLogin: false });
          } else {
            message =
              "Si è verificato un errore durante la verifica dell'account.";
          }
        });

      await Promise.resolve(p);
    } else {
      message = "Inserire i dati evidenziati in rosso";
    }

    return message;
  }

  async checkForms() {
    // verify if accept conditions
    const { accept1, accept2, collapseOpen1, collapseOpen2 } = this.state;
    let alertMessages = [];

    await this.checkLogin();

    if (this.props.enrollManifesto && !accept1) {
      alertMessages.push(
        "è necessario dichiarare di aver preso visione del bando e di accettarne le condizioni"
      );
    }
    if (!accept2) {
      alertMessages.push(
        "è necessario dichiarare di aver preso visione dell'informativa Trattamento Dati Personali e di accettarne le condizioni"
      );
    }

    if (!this.props.isLoggedIn) {
      if (collapseOpen1) {
        const message = await this.checkLogin();
        if (message) {
          alertMessages.push(message);
        }

      } else if (collapseOpen2) {
        const message = await this.checkRegistration();
        if (message) {
          alertMessages.push(message);
        }
      } else {
        alertMessages.push(
          "Specificare se hai già un account o vuoi registrarti"
        );
      }
    }

    this.setState({ alertMessages });
    return alertMessages.length > 0 ? false : true;
  }

  async submit() {
    this.checkValidation();
    const checkForms = await this.checkForms();

    if (checkForms) {
      const payload = payloadBuilder(this.state);
      payload.toLogin = this.state.toLogin;
      payload.toRegister = this.state.toRegister;
      payload.courseCode = this.state.courseCode;
      payload.statCookie = getStatCookie();
      payload.currentAA = CourseHelper.getCurrentAA(this.state.courseCode);

      // send data to server
      enrollmentService
        .startEnrollment(payload)
        .then(({ data }) => {
          const { enrollment } = data.payload;

          // else show result
          this.setState({ enrollment: enrollment, showEnrollResult: true });
        })
        .catch((error) => {
          const message = networkErrorHelper.getErrorMessage(error);

          // show error
          this.setState({ alertMessages: [message] });
        });
    }
  }

  /** */
  async sendEmailToMultipleEnroll(evt) {

    const objs = {
      userId: this.state.userId,
      courseCodeList: this.state.courseEnrolled.courseTitle,
      courseCode: this.state.courseCode
    }

    enrollmentService.sendEmailToMultipleEnroll(objs).then(({ data }) => {
      this.setState({
        multipleEnrollSendEmail: true
      })
    })
      .catch((error) => {
        console.log(error)
      });
  }//sendEmailToMultipleEnroll


  /** */
  renderEnrollResult(enrollment) {
    const { toRegister } = this.state;

    const link = ROUTES.MY_ENROLLMENT + "/" + enrollment.orderNumber;
    return (
      <BoxSuccess>
        <h6>Domanda avviata correttamente </h6>
        {toRegister && (
          <p>
            Siamo lieti di averti con noi. Ti abbiamo inviato una email per
            confermare i tuoi dati e accedere alla tua area riservata.
          </p>
        )}
              
          <p>
            <a className="wrapper-link" href={link} target="_parent">
              {" "}
              Clicca qui per i dettagli della tua domanda
            </a>
          </p>
             
      </BoxSuccess>
    );
  }

  renderPending(enrollment) {
    const route = ROUTES.MY_ENROLLMENT + "/" + enrollment.orderNumber;

    return (
      <BoxWarning>
        Hai già inviato la richiesta. 
        <NavLink className="wrapper-link" to={route}>
          {" "}
          Clicca qui per i dettagli della tua domanda
        </NavLink>
      </BoxWarning>
    );
  }

  renderShopping() {

    
    const login = this.renderFields(
      this.FIELDS_LOGIN,
      this.state.defaultValues,
      true
    );
    const register = this.renderFields(
      this.FIELDS_REGISTER,
      this.state.defaultValues,
      true
    );

    const {
      collapseOpen1,
      collapseOpen2,
      alertMessages,     
      
    } = this.state;
    const { isLoggedIn } = this.props;

    return (
      <div className="section section-muted py-5">
        <Container>
          <Row className="text-center">
            <Col className="text-center">
            <h5>Richiesta di iscrizione</h5>
            </Col>
          </Row>
          <Row>
            <Card teaser noWrapper className="no-after rounded">
              <CardBody>                              
                <div>
                  <ul className="list-left">
                    <li>
                      <MCheckbox id="accept2" onChange={this.onAccept}>
                        Dichiaro di aver preso visione e di accettare{" "}
                        <a
                          className="link-dark"
                          href={ROUTES.INFORMATIVA}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          l'Informativa Trattamento Dati Personali{" "}
                        </a>
                      </MCheckbox>
                    </li>
                    {
                      this.props.enrollManifesto && (
                        <li>
                          <MCheckbox id="accept1" onChange={this.onAccept}>
                            {(this.props.enrollManifesto === '#') ?
                              (<p>Dichiaro di accettare le condizioni del bando</p>) : (
                                <>Dichiaro di aver preso visione quanto contenuto nel
                                  <a
                                    className="link-dark"
                                    href={this.props.enrollManifesto}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  > Bando di partecipazione
                                  </a></>
                              )}

                          </MCheckbox>
                        </li>
                      )}
                  </ul>
                </div>
                {!isLoggedIn && (
                  <>
                    <AccordionHeader
                      active={collapseOpen2}
                      onToggle={() => this.toggle(2)}
                    >
                      <h5 className="text-dark card-title">
                        NON HO UN ACCOUNT - Registrati
                      </h5>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen2}>
                      {register.code}
                    </AccordionBody>
                    <Accordion>
                      <AccordionHeader
                        active={collapseOpen1}
                        onToggle={() => this.toggle(1)}
                      >
                        <h5 className="text-dark card-title">
                          HO GIA' UN ACCOUNT - Accedi
                        </h5>
                      </AccordionHeader>
                      <AccordionBody active={collapseOpen1}>
                        {login.code}
                      </AccordionBody>
                    </Accordion>
                  </>
                )}
                {alertMessages.length > 0 && (
                  <>
                    <BoxDanger>
                      <ul>
                        {alertMessages.map((message, i) => (
                          <li key={i}> {ReactHtmlParser(message)}</li>
                        ))}
                      </ul>
                    </BoxDanger>
                  </>
                )}
                  <Button
                    color="primary w-100 bg-dark mt-2 py-4"
                    onClick={() => this.submit()}
                  >
                    INVIA RICHIESTA
                  </Button>                
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    const { enrollment, loading, loadingError, showEnrollResult } = this.state;

    if (loading) return <></>;
    if (loadingError)
      return (
        <BoxDanger>
          Si è verificato un errore durante la lettura dei dati della richiesta. Se il problema persiste la preghiamo di contattare il{" "}
          <SupportoTecnico />
        </BoxDanger>
      );

    return (
      <>
        {showEnrollResult ? (
          <>{this.renderEnrollResult(enrollment)}</>
        ) : (
          <>
            {enrollment ? (
              <>{this.renderPending(enrollment)}</>
            ) : (
              <>{this.renderShopping()}</>
            )}
          </>
        )}
      </>
    );
  }
}
