import { MInput,  MSelect, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";
import React from "react";

export class FormDOC02 extends ValidatedForm {

    PAYLOADS = {
        schoolTypes: [
            { value: "", label: "Selezionare" },
            { value: "Infanzia", label: "Infanzia" },
            { value: "Primaria", label: "Primaria" },
            { value: "Secondaria di primo grado", label: "Secondaria di primo grado" },
            { value: "Secondaria di secondo grado", label: "Secondaria di secondo grado" },
        ],
    }

    FIELDS_GROUP = [

        [{
            id: "description",
            field: "description",
            label: "Titolo di specializzazione",
            infoText: "Indicare la dicitura completa del titolo di specializzazione sul sostegno conseguito",
            component: MInput,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "nation",
            field: "nation",
            label: "Inserisci Nazione Estera",
            component: MInput,
            infoText: "Nazione Estera di conseguimento titolo",
            className: "col-md-12 pt-3",
        }
        ],
        [{
            id: "istitution",
            field: "istitution",
            label:"Università Estera",
            infoText: "Indicare il nome dell'Università estera",
            component: MInput,
            className: "col-md-12 pt-3"
        }],
        [{
            id: "schoolType",
            field: "schoolType",
            label: "Grado/ordine di svolgimento del servizio",
            payload: { options: this.PAYLOADS.schoolTypes },
            component: MSelect,
            className: "col-md-6 pt-3"
        },{
            id: "aa",
            field: "aa",
            label: "Anno accademico di conseguimento del titolo",
            component: MInput,
            infoText: "inserire formato AAAA/AAAA",
            className: "col-md-6 pt-3",
        }],
        [{
            id: "dataConseguimento",
            field: "dataConseguimento",
            label: "Data conseguimento titolo",
            component: MInput,
            type: "date",
            className: "col-md-6 pt-3",
        },
        {
            id: "dataPresentazioneDomanda",
            field: "dataPresentazioneDomanda",
            label: "Data di presentazione dell'Istanza di riconoscimento",
            component: MInput,
            type: "date",
            className: "col-md-6 pt-3",
        }
        ]
    ];

    ERROR_MESSAGES = {
        nation: "Valore obbligatorio",
        description: "Valore obbligatorio",  
        istitution: "Valore obbligatorio",  
        schoolType: "Valore obbligatorio",  
        aa: "Inserire l'anno accademico in formato AAAA/AAAA",
        dataConseguimento: "Valore obbligatorio",
        dataPresentazioneDomanda: "Valore obbligatorio",
    };

    validation = {
        nation: (value) => value !== "",
        description: (value) => value !== "",
        istitution: (value) => value !== "",
        schoolType: (value) => value !== "",
        aa: (value) => this.validateAA(value),
        dataConseguimento: (value) => value !== "",
        dataPresentazioneDomanda: (value) => value !== "",
    };

    emptyFields = {
        description: "",
        nation: "",
        istitution:"",
        schoolType:"",
        aa: "",
        dataConseguimento: "",
        dataPresentazioneDomanda: "",
    };

    state = {
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields,
        },
    };

    validateAA = (value) => {
        let aa = /^(\d{4})\/(\d{4})$/.test(value);
    
        if (!aa) {
          return false;
        } else {
          aa = value.split("/");
          if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
            return true;
          } else {
            return false;
          }
        }
      }; //validateAA

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }  

    getPayload = () => {

        const payload =[];

        this.payload = payloadBuilder(this.state);      
        this.payload.qualificationType = "AbilitazioneSostegno";
        this.payload.qualificationCode = "DOC02";
        this.payload.template = 'AbilitazioneSostegno';        
        this.payload.description = this.payload.description + ' ' + this.payload.schoolType
        payload.push(this.payload);
        return payload;
    };

    render() {
        const { defaultValues } = this.state;
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (
            <>
                {code}
            </>
        );
    }
}