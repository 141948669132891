
export class CourseHelper {

    static getCurrentAA(courseCode) {

        // TODO REACT_APP_AA_YEAR: da sistemare: è un pezza. eliminare la chimata a questo metodo
        let aa = parseInt(process.env.REACT_APP_AA_YEAR);
        if (this.isReggio30CFU(courseCode)
            || this.isTFAReggio(courseCode)
            || this.isUnicamillus30CFU(courseCode)
            || this.isTchaikovsky30CFU(courseCode)
            || this.isReggio(courseCode)) {
            aa = 2023;
        }

        return aa;
    }

    static isFree(course) {

        let result = false;
        if (course && course.courseType) {
            if (course.courseType.includes('FREE')) {
                result = true;
            }
        }

        return result;
    }

    static isPFU(courseCode) {
        return (courseCode === 'PFU5CFU2' || courseCode === 'PFU5CFU')
    }

    static isReggio30CFU(courseCode) {
        return (courseCode === 'RC30CFUART13');
    }

    static isUnicamillus30CFU(courseCode) {
        return (courseCode.startsWith('UM30'));
    }

    static isCFU(courseCode) {
        return (courseCode === 'CS24CFU' || courseCode === 'CSU24CFU' || courseCode === 'PFU5CFU')
    }

    static isReggio(courseCode) {
        return (courseCode === 'CSUREGGIO')
    }

    static isTFAReggio(courseCode) {
        return (courseCode.startsWith('TFA_IX_CICLO'));      
    }

    static isCFUAll(courseCode) {
        return (courseCode === 'RC60CFUALL1' || courseCode === 'RC30CFUALL2' )
    }

    static isTFAReggioRiservato(courseCode) {
       return (courseCode === 'TFA_IX_CICLO_RISERVATO')
    }

    static isTFASoprannumerariReggio(courseCode) {
        return (courseCode === 'TFA_IX_CICLO_SOPRANNUMERARI_IDONEI')
    }

    static isTFAOrdinarioReggio(courseCode) {
        return (courseCode === 'TFA_IX_CICLO_ORDINARIO')
    }

    static isReggioBonifico(courseCode) {
        return (courseCode === 'RC30CFUART13'
            || courseCode === 'TFA_IX_CICLO_RISERVATO'
            || courseCode === 'TFA_IX_CICLO_ORDINARIO'
            || courseCode === 'TFA_IX_CICLO_SOPRANNUMERARI_IDONEI'
            || courseCode === 'RCCFUALL'
            || courseCode === 'RC60CFUALL1' || courseCode === 'RC30CFUALL2')
    }

    static isTchaikovsky30CFU(courseCode) {
        
        return (courseCode.startsWith('CM30CFU'));
    }

    static isTchaikovsky(courseCode) {
        
        return (courseCode.startsWith('CM30CFU'));
    }

    static isUnicamillus(courseCode) {
        
        return (courseCode.startsWith('MU') || courseCode.startsWith('MDU')
            || courseCode.startsWith('MP') || courseCode.startsWith('BU')
            || (courseCode.startsWith('CSU') && !courseCode.startsWith('CSUR')) || courseCode.startsWith('PDU')
            || (courseCode.startsWith('PCU') && !courseCode.startsWith('PCUM'))
            || (courseCode.startsWith('PLU') && !courseCode.startsWith('PLUM'))
            || courseCode.startsWith('UPD') || courseCode.startsWith('UPL')
            || courseCode.startsWith('PFU') || courseCode.startsWith('MSLU')
            || courseCode.startsWith('UM30')
            || courseCode.startsWith('UM30CFU'));
    }

    static isDSII(courseCode) {

        let result = false;
        if (courseCode) {
            if (courseCode.startsWith('DS')) {
                let aa = process.env.REACT_APP_AA_YEAR;
                aa--;
                let ends = aa.toString().slice(2, 4)
                if (courseCode.endsWith(ends)) {
                    result = true;
                }
            }
        }

        return result;
    }

    static isBUII(courseCode) {

        let result = false;
        if (courseCode) {
            if (courseCode.startsWith('BU')) {
                let aa = process.env.REACT_APP_AA_YEAR;
                aa--;
                let ends = aa.toString().slice(2, 4)
                if (courseCode.endsWith(ends)) {
                    result = true;
                }
            }
        }

        return result;
    }

    /** */
    static calcDurationByLog(dataActivityLog) {
        let sumDiffTotal = 0;
        let sumDiffPartial = 0;
        let sDate = '';
        let eDate = '';
        let delta = 60 * 10;

        dataActivityLog.forEach((item) => {
            if (sDate === '' && eDate === '') { sDate = item.time; }
            if (sDate !== '' && eDate === '') { eDate = item.time; }

            if (sDate !== '' && eDate !== '') {
                let timeDifference = eDate - sDate;

                if (timeDifference <= delta) {
                    sumDiffPartial += timeDifference
                }
                else {
                    sumDiffTotal += sumDiffPartial;
                    sumDiffPartial = 0;
                }

                sDate = eDate;
                eDate = '';
            }
        });

        return sumDiffTotal;
    }//calcDurationByLog
}