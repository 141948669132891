import React from "react";
import ReactHtmlParser from 'react-html-parser';
import {
    MInput,
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentService,
    networkErrorHelper
} from "libs";

import {
    Col, Row
} from "design-react-kit";
import { FormDOC01 } from "./enrollSostegno/FormDOC01";
import { FormDOC02 } from "./enrollSostegno/FormDOC02";

export class EnrollFillAdesioniSostegno extends ValidatedForm {

    PROFILE_TYPE = {
        DOC01: 'DOC01',
        DOC02: 'DOC02',
    };

    PAYLOADS = {
        schoolTypes: [
            { value: "", label: "Selezionare" },
            { value: "Infanzia", label: "Infanzia" },
            { value: "Primaria", label: "Primaria" },
            { value: "Secondaria di primo grado", label: "Secondaria di primo grado" },
            { value: "Secondaria di secondo grado", label: "Secondaria di secondo grado" },
        ],
        profileTypes: [
            { value: "", label: "Selezionare" },
            { value: this.PROFILE_TYPE.DOC01, label: "Sono un Docente con tre anni di servizio sul sostegno, anche non continuativi, nei cinque anni precedenti" },
            { value: this.PROFILE_TYPE.DOC02, label: "Sono un Docente in possesso di un titolo di specializzazione sul sostegno conseguito all'estero" },
        ],
    }

    FIELDS_GROUP = [
        [
            {
                field: "schoolType",
                label: "Selezionare il Grado di interesse ",
                payload: { options: this.PAYLOADS.schoolTypes },
                component: MSelect,
                className: "col-md-6"
            }
        ], [
            {
                id: "competitionClass",
                field: "competitionClass",
                label: "Classe di concorso",
                infoText: "Indicare una classe di concorso di interesse",
                component: MInput,
                className: "col-md-6"
            },
            {
                id: "province",
                field: "province",
                label: "Sigla provincia",
                infoText: "Indicare la Provincia di inserimento nelle GPS",
                component: MInput,
                className: "col-md-6"
            }
        ], [
            {
                field: "profileType",
                label: "Selezionare il profilo di appartenenza",
                payload: { options: this.PAYLOADS.profileTypes },
                component: MSelect,
                onChange: (_, value) => this.onChangeProfileType(_, value),
                className: "col-md-12"
            }
        ]
    ];

    ERROR_MESSAGES = {
        schoolType: 'Specificare il grado di interesse',
        competitionClass: 'Specificare una classe di concorso',
        province: 'Specificare la Provincia di inserimento nelle GPS',
        profileType: 'Specificare il profilo di appartenenza'
    };

    validation = {
        schoolType: value => value > '',
        competitionClass: value => value > '',
        province: value => value > '',
        profileType: value => value > '',
    };

    emptyFields = {
        schoolType: '',
        competitionClass: '',
        province: '',
        profileType: ''
    };

    state = {
        _formRefProfile1: null,
        _formRefProfile2: null,
        selectedProfileType: null,
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {

        const  {schoolType,  competitionClass, province, profileType} = this.payload;

        this.payload.pluginConfig = {
           schoolType,  
           competitionClass, 
           province, 
           profileType
        };

        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const newState = {
            defaultValues,
            _formRefProfile1: React.createRef(),
            _formRefProfile2: React.createRef(),
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    onChangeProfileType = (id, value) => {        
        this.setState({ selectedProfileType: value });
    };

    onSubmit = () => {

        let validationSummary = '';
        let isValid = false;
        let isValidProfile1 = true;
        let isValidProfile2 = true;

        const { _formRefProfile1, _formRefProfile2, selectedProfileType } = this.state;
        let qualifications = [];

        this.payload = payloadBuilder(this.state);
        let isValidForm = this.checkValidation();

        if (!isValidForm) {
            validationSummary += '<li>Inserire i campi obbligatori in rosso</li>';
        }

        // validate FormTitleA
        if (_formRefProfile1 && (selectedProfileType === this.PROFILE_TYPE.DOC01)) {


            isValidProfile1 = _formRefProfile1.current.onSubmit();
            if (isValidProfile1) {
                // get payload
                qualifications= _formRefProfile1.current.getPayload();               
            } else {
                validationSummary += '<li>Indicare tutti i dati relativi alle tre annualità di servizio sul sostegno</b></li>';
            }
        } 

        if (_formRefProfile2 && (selectedProfileType === this.PROFILE_TYPE.DOC02)) {
            isValidProfile2 = _formRefProfile2.current.onSubmit();

            // get payload
            if (isValidProfile2) {
                // get payload
                qualifications= _formRefProfile2.current.getPayload();  
            } else {
                validationSummary += '<li>Titolo di specializzazione sul sostegno conseguito all\'estero con dati mancanti.</li>';
            }
        } 

        // if valid call saveRemote: send data to server
        isValid = isValidProfile1 && isValidProfile2 && isValidForm;

        if (isValid) {
            const payloadQualification = {
                idEnrollment: this.props.enrollData.idEnrollment,
                qualifications: qualifications
            };

            //  invia al server (array di userEnrollmentQualification)
            function myFunction() {
                return new Promise((resolve, reject) => {
                    enrollmentService.updateEnrollmentQualification(payloadQualification)
                        .then(() => {
                            resolve();
                        })
                        .catch(errors => {
                            validationSummary += '<li>Errore imprevisto nell\'invio dei dati. Se il problema persiste contattare il supporto tecnico.</li>';
                            networkErrorHelper.notify(errors);
                            isValid = false;
                        });
                });
            }

            // Call the function and await the result
            myFunction()
                .then(() => {
                    // Handle success
                })
                .catch(errors => {
                    validationSummary += '<li>Errore imprevisto nell\'invio dei dati. Se il problema persiste contattare il supporto tecnico.</li>';
                    networkErrorHelper.notify(errors);
                    isValid = false;
                });
        }

        // set state with validation summary
        if (validationSummary !== '') {
            validationSummary = '<ul>' + validationSummary + '</ul>';
        }
        this.setState({ validationSummary: validationSummary });

        return isValid;
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);
        this.setState({ formActive: false });
    };

    render() {

        const {
            loading, defaultValues, validationSummary,
            _formRefProfile1, _formRefProfile2, selectedProfileType
        } = this.state;

        if (loading) return <></>;
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            <div><form>{code}</form></div>
            <Row>
                <Col>
                    {selectedProfileType === this.PROFILE_TYPE.DOC01 && (<>
                        <FormDOC01
                            ref={_formRefProfile1}
                        />
                    </>)}

                    {selectedProfileType === this.PROFILE_TYPE.DOC02 && (<>
                        <FormDOC02
                            ref={_formRefProfile2}
                        />
                    </>)}
                </Col>
            </Row>
            <Row>
                <div className="w-100">
                    {validationSummary &&
                        <div className="alert alert-danger mt-3" role="alert">{ReactHtmlParser(validationSummary)}</div>}
                </div>
            </Row>
        </>);
    }
}