import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger,  MnemoLoading, SupportoTecnico, Title } from "components/misc";
import {  learningService, networkErrorHelper } from "libs";
import { Icon } from "design-react-kit";
import { ROUTES } from "const";
import { rc30ExamService } from "libs/api/rc30ExamService";

class RC30ExamSubject extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        userCourse: 0,
        rCode: '',        
        examSubject: false
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, rCode });

                rc30ExamService.myExamSubject(rCode)
                    .then(({ data }) => {
                        
                        const { examSubject } = data.payload;
                        this.setState({ loading: false, examSubject, loadingError: false });
                    })
                    .catch(error => {

                        if (!networkErrorHelper.is404(error)) {
                            networkErrorHelper.notify(error);
                        }

                        this.setState({rCode, loading: false, loadingError: true });
                    });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, rCode, examSubject, userCourse } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const prefix = (userCourse.pluginConfig.competitionClass === 'AB24' || userCourse.pluginConfig.competitionClass === 'AB25') ? `Di queste tracce potrà sceglierne liberamente una e su questa potrà svolgere una lezione simulata da esporre in italiano utilizzando esempi specifici e illustrazioni pratiche concernenti direttamente la didattica dell'inglese come lingua straniera nella scuola.
        Le ricordiamo inoltre che, qualora lo volesse, anche la progettazione dell'Unità di Apprendimento può essere svolta sullo stesso argomento.` : `Di queste tracce potrà sceglierne liberamente una e su questa potrà svolgere la lezione simulata.`;

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Argomenti esami</Title>
            <hr/>
            <p>Gli argomenti che la commissione esaminatrice ha scelto per la prova finale del percorso abilitante 30 CFU (ex art.13 DPCM 4 agosto 2023) sono:</p>
            <ul>
                <li>{examSubject.title1}</li>
                <li>{examSubject.title2}</li>
                <li>{examSubject.title3}</li>
            </ul>            
            <p>{prefix}</p>
            <p>Le ricordiamo inoltre che, qualora lo volesse, anche la progettazione dell’Unità di Apprendimento può essere svolta sullo stesso argomento.</p>
            
        </>)
    }
}

export default withRouter(RC30ExamSubject);